$white: #ffffff;
$black: #141f38;
$blue: #0060aa;
$blueSecondary: #003c74;
$blueThird: #e1f6ff;
$blueHover: #47bdff;
$borderToLight: rgba(0, 0, 0, 0.15);
$borderToDark: rgba(255, 255, 255, 0.15);

$mainFont: "Axiforma";
