.header {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    &--redirect {
        position: relative;

        .header__bottom {
            display: none;
        }
    }
}

.header__top {
    padding: 26px 0;
    background: $white;
    border-bottom: 1px solid $borderToDark;

    .container {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 767px) {
            justify-content: flex-start;
        }
    }
}

.header__top-links {
    display: flex;
    align-items: center;
    gap: 50px;

    @media (max-width: 1024px) {
        gap: 25px;
    }

    @media (max-width: 767px) {
        gap: 10px;
        flex-direction: column;
        align-items: flex-end;
        flex-shrink: 0;
        margin-left: auto;

        svg {
            display: none;
        }
    }
}

.header__top-link {
    display: flex;
    align-items: center;
    gap: 10px;
    color: $black;
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;

    @media (max-width: 1024px) {
        font-size: 15px;
        line-height: 20px;
    }

    @media (max-width: 767px) {
        font-size: 13px;
        line-height: 16px;
    }

    svg {
        flex-shrink: 0;
    }

    &:hover {
        color: $blueHover;
    }
}

.logo {
    margin-right: 20px;
}

.header__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $blueSecondary;
    height: 80px;
    padding: 0 40px;

    @media (max-width: 1024px) {
        padding: 0 20px;
    }

    @media (max-width: 767px) {
        position: fixed;
        height: 100vh;
        height: var(--app-height);
        width: 100%;
        z-index: 500;
        left: 0;
        top: 0;
        flex-direction: column;
        align-items: flex-start;
        padding: 50px 20px 25px;
        transition: 0.4s ease-out;
        transform: translateX(-100%);

        &.active {
            transform: translateX(0);
        }
    }
}

.nav {
    display: flex;
    align-items: center;
    gap: 50px;

    @media (max-width: 1024px) {
        gap: 16px;
    }

    @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.nav__link {
    color: $white;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.3px;
    text-transform: uppercase;

    @media (max-width: 1024px) {
        font-size: 14px;
        line-height: 18px;
    }

    @media (max-width: 767px) {
        font-size: 24px;
        line-height: 28px;
    }

    &:hover,
    &.active {
        color: $blueHover;
    }
}

.language {
    display: flex;
    flex-direction: row-reverse;

    @media (max-width: 767px) {
        flex-direction: row;
    }
}

.language__current {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    color: $white;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.3px;
    gap: 14px;
    cursor: pointer;

    svg {
        transition: 0.2s ease-out;
        margin-top: -3px;
    }

    &:hover {
        svg {
            path {
                fill: $blueHover;

                @media (max-width: 767px) {
                    fill: $white;
                }
            }
        }
    }

    &.active {
        svg {
            transform: rotate(90deg);

            @media (max-width: 767px) {
                transform: rotate(-90deg);

                path {
                    fill: $blueHover;
                }
            }
        }
    }
}

.language__list {
    display: flex;
    align-items: center;
    margin-right: 20px;
    gap: 15px;
    width: 0;
    overflow: hidden;
    transition: 0.2s ease-out;

    @media (max-width: 767px) {
        margin-right: 0;
        margin-left: 20px;
    }

    &.active {
        width: 100%;
    }
}

.language__link {
    color: $white;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.3px;

    &:hover {
        color: $blueHover;
    }
}

.header__close {
    display: none;

    @media (max-width: 767px) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;
    }
}

.hamburger {
    display: none;
    flex-direction: column;
    gap: 6px;
    width: 25px;
    height: 12px;
    margin-left: 15px;

    @media (max-width: 767px) {
        display: flex;
    }

    span {
        height: 2px;
        width: 100%;
        background: $black;
        flex-shrink: 0;
    }
}
