.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: 0.3s;
    border: 0;
    outline: 0;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
    line-height: 20px;
    box-shadow: 0px 4px 50px #000000;
    background: $blue;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    flex-shrink: 0;
    border-radius: 10px;
    border: 0;
    padding: 25px;
    font-family: $mainFont;
    color: $black;

    @media (max-width: 767px) {
        font-size: 20px;
        padding: 16px;
    }

    &:hover {
        box-shadow: 0px 4px 30px #000000;
        text-shadow: #fff 5px 2px 20px;
    }
}
