.description-head {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 50px;
  margin-top: 40px;

  @media (max-width: 1024px) {
    margin-top: 0;
    margin-bottom: 30px;
  }
}

.description-head__name {
  font-size: 60px;
  font-weight: 700;
  line-height: 60px;

  @media (max-width: 1024px) {
    font-size: 48px;
    line-height: 54px;
  }
}

.description-head__articul {
  span {
    &:first-child {
      font-size: 25px;
      font-weight: 500;
      line-height: 30px;
      color: #a3a3a3;
    }

    &:last-child {
      font-size: 25px;
      font-weight: 800;
      line-height: 30px;
    }
  }
}

.description-body-top {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  margin-bottom: 50px;

  @media (max-width: 1360px) {
    gap: 30px;
  }
}

.description-body__col {
  width: calc(33.33% - 33.33px);
  display: flex;
  flex-direction: column;

  @media (max-width: 1360px) {
    width: calc(33.33% - 20px);
  }

  @media (max-width: 1024px) {
    width: calc(50% - 15px);

    &:first-child {
      width: 100%;
      flex-direction: row;
      align-items: center;
      gap: 20px;
    }
  }

  @media (max-width: 767px) {
    width: 100%;

    &:first-child {
      flex-direction: column;
      gap: 0;
      align-items: flex-start;
    }
  }
}

.description-body__photo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  aspect-ratio: 1 / 1;
  margin-bottom: 50px;
  width: 100%;

  @media (max-width: 1024px) {
    max-width: 260px;
  }

  @media (max-width: 767px) {
    margin-bottom: 20px;
    max-width: 100%;
  }
}

.description-body__actions {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (max-width: 1024px) {
    gap: 20px;
  }

  a {
    display: flex;
    gap: 20px;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    color: $black;
    letter-spacing: 0.3px;
    text-transform: uppercase;

    &:hover {
      color: $blueHover;
    }
  }
}

.description-body__text {
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 50px;
  flex-grow: 1;

  @media (max-width: 1360px) {
    padding: 25px;
  }
}

.description-body__info {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 50px;
  background: #f6f6f6;

  @media (max-width: 1360px) {
    padding: 25px;
  }
}

.description-body__info-title {
  font-size: 25px;
  font-weight: 800;
  line-height: 30px;
}

.description-body__info-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: $blue;
}

.description-body__info-list {
  padding: 0;
  list-style-type: none;
  margin: 0;

  @media (max-width: 1360px) {
    padding-left: 15px;
  }

  li {
    position: relative;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      background: url(../images/icons/list-icon.svg) no-repeat;
      background-size: contain;
      width: 17px;
      height: 14px;
      left: -30px;
      top: 4px;
    }
  }
}

.description-body-bottom {
  display: flex;
  align-items: flex-start;
  gap: 50px;
  margin-bottom: 100px;

  @media (max-width: 1024px) {
    margin-bottom: 50px;
    flex-direction: column;
    gap: 25px;
  }
}

.description-body__filter {
  width: calc(32.5% - 25px);
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 1024px) {
    max-width: 100%;
    min-width: 350px;
  }
}

.description-body__filter-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .select2-container {
    width: 200px !important;

    @media (max-width: 1360px) {
      width: 160px !important;
    }

    .select2-selection--single {
      border-radius: 5px;
      background: #fff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      border: 0;
      height: 45px;

      .select2-selection__rendered {
        display: flex;
        height: 100%;
        align-items: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        padding: 0 20px;
        opacity: 0.7;
        color: #000;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .select2-selection__arrow {
        height: 100%;
        right: 10px;
      }
    }
  }
}

.description-body__filter-name {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.select2-results__option {
  padding: 8px 20px !important;
  font-size: 14px;
}

.description-body__links {
  display: flex;
  flex-direction: column;
  width: calc(67.5% - 25px);
  gap: 10px;

  @media (max-width: 1024px) {
    width: 100%;
  }

  & > a {
    color: $blue;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 0 20px;
    height: 45px;
    font-size: 16px;
    font-weight: 400;
    line-height: 50px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      color: $blueHover;
    }
  }
}

.description-body__filter-button {
  margin-top: 50px;

  a {
    width: 100%;
    color: $white;
    background: $blueSecondary;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;

    @media (max-width: 1360px) {
      height: 45px;
    }

    &:hover {
      background: $blueHover;
    }
  }
}

.description-body__pagination {
  width: 100%;
  margin-top: 50px;
  display: flex;
}

.description-body__pagination-prev,
.description-body__pagination-next {
  flex-grow: 1;
  background: $blueThird;
  border-left: 1px solid rgba(0, 96, 170, 0.15);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1360px) {
    height: 45px;
  }

  &:hover {
    background: $blueHover;
  }
}

.description-body__pagination-prev {
  border-left: 0;
  border-right: 1px solid rgba(0, 96, 170, 0.15);
}

.description-body__pagination-page {
  height: 60px;
  display: flex;
  width: 60px;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: $blue;
  background: $blueThird;

  @media (max-width: 1360px) {
    width: 45px;
    height: 45px;
  }

  &:hover {
    background: $blueHover;
  }

  &.active {
    background: $blue;
    color: $white;
  }
}
