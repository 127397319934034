:root {
    --app-height: 100%;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    font-family: $mainFont;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 189px;

    @media (max-width: 767px) {
        padding-top: 109px;
    }

    &.redirect-page {
        padding-top: 0 !important;
    }
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

a {
    text-decoration: none;
}

a,
a:active {
    text-decoration: none;
    transition: 0.3s;
}

a:hover {
    text-decoration: none;
    transition: 0.3s;
}

h1 {
}

h2 {
    font-size: 60px;
    margin-bottom: 84px;
    font-weight: 700;
    line-height: 60px;
    text-align: center;

    @media (max-width: 1024px) {
        margin-bottom: 40px;
        font-size: 34px;
        line-height: 34px;
    }

    @media (max-width: 767px) {
        margin-bottom: 25px;
    }
}

h3 {
}

p {
    margin: 0;
}

svg {
    path,
    circle,
    line,
    polyline {
        transition: 0.3s;
    }
}

img {
    max-width: 100%;
}

.container {
    width: 1150px;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;

    @media (max-width: 1360px) {
        width: 960px;
        padding: 0 16px;
    }

    @media (max-width: 1024px) {
        width: 720px;
    }

    @media (max-width: 767px) {
        width: 100%;
    }
}

.tabs {
    position: relative;
}

.tabs__content {
    display: none;
}

.tabs__content.active {
    display: flex;
    flex-direction: column;
}

.hidden {
    overflow: hidden !important;

    @media (max-width: 991px) {
        padding-right: 0;
    }
}

hr {
    height: 1px;
    border: 0;
    border-bottom: 1px solid $borderToLight;
    margin: 0;
    width: 100%;
}

.checkbox-container {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    position: relative;
    height: 20px;
    padding-left: 40px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: normal;
    color: $black;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    font-family: $mainFont;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 2px solid $blue;
    border-radius: 2px;
    transition: 0.3s;
}

.checkmark:after {
    content: "";
    position: absolute;
    opacity: 0;
    transition: 0.3s;
    left: 6px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid $blue;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.checkbox-container input:checked ~ .checkmark:after {
    opacity: 1;
}

.form-group {
    display: flex;
    width: 100%;

    input {
        font-size: 16px;
        color: $white;
        padding: 12px 20px;
        font-weight: 400;
        line-height: 24px;
        padding: 12px 20px;
        background: #004e8a;
        border-radius: 6px;
        border: 0;
        outline: none;
        width: 100%;
        font-family: $mainFont;

        &::placeholder {
            color: rgba(255, 255, 255, 0.5);
        }
    }

    textarea {
        font-size: 16px;
        color: $white;
        padding: 12px 20px;
        font-weight: 400;
        line-height: 24px;
        padding: 12px 20px;
        background: #004e8a;
        border-radius: 6px;
        border: 0;
        outline: none;
        width: 100%;
        resize: none;
        font-family: $mainFont;
        height: 200px;

        &::placeholder {
            color: rgba(255, 255, 255, 0.5);
        }
    }

    .select2 {
        width: 100% !important;

        .select2-selection--single {
            border: 0;
            border-radius: 5px;
            height: 45px;

            .select2-selection__rendered {
                height: 100%;
                display: flex;
                align-items: center;
                padding: 10px 20px;
            }

            .select2-selection__arrow {
                height: 45px;
                width: 40px;

                b {
                    background: url(../images/icons/arrow.svg) no-repeat;
                    background-size: 10px 10px;
                    background-position: center;
                    border: 0;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    left: 15px;
                    top: 14px;
                }
            }
        }

        &.select2-container--open {
            .select2-selection__arrow {
                transform: rotate(180deg);
            }
        }
    }
}

.select2-dropdown {
    border: 0 !important;
}
