.contacts {
  display: flex;
  margin-bottom: 96px;

  @media (max-width: 1024px) {
    margin-bottom: 50px;
  }

  @media (max-width: 767px) {
    margin-bottom: 30px;
    flex-direction: column;
  }
}

.maps {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
}

.map__item {
  position: relative;
  width: 100%;
}

.map__title {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background: $blue;
  padding: 20px 25px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: $white;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
}

.contacts__form {
  display: flex;
  flex-direction: column;
  padding: 50px;
  background: $blue;
  width: 400px;
  flex-shrink: 0;
  gap: 20px;

  @media (max-width: 1024px) {
    gap: 15px;
    width: 300px;
    padding: 20px;
  }

  @media (max-width: 767px) {
    width: 100%;
  }

  button {
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-family: $mainFont;
    border: 0;
    outline: none;
    box-shadow: 0;
    background: transparent;
    color: $white;
    cursor: pointer;
    align-self: end;
    transition: 0.2s ease-out;

    &:hover {
      color: $blueHover;
    }
  }
}

.contacts__form-title {
  margin-bottom: 12px;
  font-size: 25px;
  font-weight: 800;
  line-height: 30px;
  color: $white;
}

.requisites {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  margin-bottom: 93px;

  @media (max-width: 1024px) {
    margin-bottom: 50px;
    gap: 20px;
  }

  @media (max-width: 767px) {
    margin-bottom: 30px;
    gap: 30px;
  }
}

.requisites__item {
  width: calc(33.33% - 33.33px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 1024px) {
    width: calc(50% - 10px);
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}

.requisites__flag {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 20px;

  img {
    width: 100%;
    height: 100%;
  }
}

.requisites__name {
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
}

.requisites__country {
  font-size: 25px;
  font-weight: 800;
  line-height: 30px;
  margin-bottom: 34px;

  @media (max-width: 1024px) {
    margin-bottom: 15px;
  }
}

ul {
  margin-bottom: 32px;

  @media (max-width: 1024px) {
    margin-bottom: 15px;
  }

  li {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.requisites__links {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.requisites__link {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 99px;
  background: $blueThird;
  padding: 10px 20px 10px 10px;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  color: $black;

  &:hover {
    background: $blueHover;
    color: $white;
  }
}
