.about-us {
    display: flex;
    flex-direction: column;
}

.about-us__text {
    display: flex;
    flex-wrap: wrap;
    gap: 40px 50px;
    padding: 80px 0 66px;

    @media (max-width: 767px) {
        padding: 40px 0;
    }

    ul {
        flex-grow: 1;

        li {
            position: relative;
            padding-left: 18px;

            &:before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 7px;
                background: $blueHover;
                border-radius: 50%;
                width: 5px;
                height: 5px;
            }
        }
    }
}

.about-us__text-wrapper {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    width: 600px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.about-us__text-title {
    font-size: 25px;
    font-weight: 800;
    line-height: 30px;
    width: 100%;
}

.certificate {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 100px;

    @media (max-width: 767px) {
        margin-bottom: 50px;
    }
}

.certificate__title {
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;
    color: $white;
    background: $blue;
    padding: 30px 40px;

    @media (max-width: 1024px) {
        font-size: 22px;
        line-height: 28px;
    }

    @media (max-width: 767px) {
        padding: 20px;
        font-size: 18px;
        line-height: 24px;
    }
}

.certificate__text {
    color: $black;
    background: $blueThird;
    padding: 40px 50px;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;

    @media (max-width: 1024px) {
        padding: 30px 40px;
    }

    @media (max-width: 767px) {
        padding: 20px;
        font-size: 14px;
        line-height: 20px;
    }
}

.fakts {
    display: flex;
    flex-wrap: wrap;

    & > img {
        width: 100%;
    }
}

.fakts__title {
    font-size: 60px;
    font-weight: 700;
    line-height: 60px;
    margin-bottom: 60px;
    width: 100%;
    text-align: center;

    @media (max-width: 1024px) {
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 30px;
    }
}

.fakts__left {
    display: flex;
    width: 500px;
    height: 500px;
    overflow: hidden;

    @media (max-width: 1360px) {
        width: 400px;
        height: 430px;
    }

    @media (max-width: 1024px) {
        width: 100%;
        height: auto;
    }

    img {
        transition: 0.2s ease-out;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &:hover {
        img {
            transform: scale(1.02);
        }
    }
}

.fakts__right {
    display: flex;
    width: 650px;
    height: 500px;
    overflow: hidden;

    @media (max-width: 1360px) {
        width: 528px;
        height: 430px;
    }

    @media (max-width: 1024px) {
        width: 100%;
        height: auto;
    }

    img {
        transition: 0.2s ease-out;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &:hover {
        img {
            transform: scale(1.02);
        }
    }
}

.square {
    padding-top: 100px;
    padding-bottom: 70px;
    border-bottom: 1px solid $borderToLight;
    margin-bottom: 100px;
}

.square__title {
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 48px;
    color: $blue;
    margin-bottom: 40px;
    padding-left: 50px;

    @media (max-width: 767px) {
        padding-left: 25px;
        font-size: 24px;
        line-height: 32px;
    }
}

.square__wrapper {
    display: flex;
    align-items: flex-start;
    gap: 50px;
    padding-left: 50px;
    position: relative;

    @media (max-width: 1024px) {
        flex-direction: column;
    }

    @media (max-width: 767px) {
        padding-left: 25px;
    }

    &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: -188px;
        width: 1px;
        background-color: $borderToLight;
        height: 450px;
    }
}

.square__info {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    gap: 35px;
    width: 400px;
    flex-shrink: 0;
    margin-right: auto;

    @media (max-width: 767px) {
        width: 100%;
    }
}

.square__item {
    position: relative;
    display: flex;
    align-items: center;
    gap: 6px;

    &:first-child {
        height: 130px;
        background: url(../images/map-1.svg) no-repeat;
        background-position: center;

        .square__value {
            color: #9d2235;
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            left: -55px;
            border-radius: 50%;
            width: 10px;
            height: 10px;
            background-color: #9d2235;
            top: 50%;
            transform: translateY(-50%);

            @media (max-width: 767px) {
                left: -30px;
            }
        }
    }

    &:last-child {
        height: 160px;
        background: url(../images/map-2.svg) no-repeat;
        background-position: center;

        .square__value {
            color: #0072ce;
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            left: -55px;
            border-radius: 50%;
            width: 10px;
            height: 10px;
            background-color: #0072ce;
            top: 50%;
            transform: translateY(-50%);

            @media (max-width: 767px) {
                left: -30px;
            }
        }
    }
}

.square__name {
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;

    @media (max-width: 767px) {
        font-size: 28px;
        line-height: 34px;
    }
}

.square__value {
    font-size: 45px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;

    @media (max-width: 767px) {
        font-size: 32px;
        line-height: 40px;
    }
}

.square__list {
    display: flex;
    flex-direction: column;
    list-style-type: disc;

    li {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
    }
}

.partners {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 100px;

    @media (max-width: 767px) {
        margin-bottom: 50px;
    }
}

.partners__title {
    text-align: center;
    width: 100%;
    margin-bottom: 84px;
    font-size: 60px;
    font-weight: 700;
    line-height: 60px;

    @media (max-width: 1024px) {
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 30px;
    }
}

.partners__item {
    display: flex;
    aspect-ratio: 1 / 1;
    width: 20%;
    align-items: center;
    justify-content: center;

    @media (max-width: 1024px) {
        width: 33.33%;
        max-height: 150px;
    }

    @media (max-width: 767px) {
        width: 50%;
        padding: 15px;
    }
}
