.offer {
  height: 550px;
  background: url(../images/banner-1.png) no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: -80px;
  padding-top: 174px;

  @media (max-width: 1024px) {
    height: 350px;
    padding-top: 140px;
  }

  @media (max-width: 767px) {
    margin-top: 0;
    padding-top: 80px;
  }

  h1 {
    font-size: 80px;
    color: $white;
    font-weight: 800;
    line-height: 78px;
    max-width: 638px;

    @media (max-width: 1024px) {
      font-size: 50px;
      line-height: 50px;
      max-width: 450px;
    }

    span {
      font-weight: 400;
    }
  }

  &--inner {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 174px;
    padding-bottom: 126px;
    height: 384px;

    @media (max-width: 1024px) {
      height: 240px;
      padding-top: 140px;
      padding-bottom: 80px;
    }

    @media (max-width: 767px) {
      padding-top: 80px;
      padding-bottom: 40px;
    }

    & > img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      top: 0;
      left: 0;
    }
  }
}

.advantages {
  padding: 100px 0 50px;
}

.advantages__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 38px;

  @media (max-width: 1024px) {
    justify-content: center;
  }

  @media (max-width: 767px) {
    gap: 20px;
  }
}

.advantages__item {
  width: calc(20% - 30.4px);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    width: calc(33.33% - 26px);
  }

  @media (max-width: 767px) {
    width: calc(50% - 10px);
  }
}

.advantages__icon {
  display: flex;
  width: 120px;
  height: 120px;
  margin: 0 auto 30px;
}

.advantages__name {
  font-size: 25px;
  font-weight: 800;
  line-height: 30px;
  margin-bottom: 22px;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 15px;
  }
}

.advantages__text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  transition: 0.2s ease-out;
  margin-top: auto;
}

.products {
  padding-bottom: 100px;

  @media (max-width: 767px) {
    padding-bottom: 50px;
  }
}

.products__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;

  @media (max-width: 767px) {
    gap: 20px;
  }
}

.product {
  display: flex;
  flex-direction: column;
  width: calc(25% - 37.5px);
  color: $black;

  @media (max-width: 1024px) {
    width: calc(50% - 25px);
  }

  @media (max-width: 1024px) {
    width: calc(50% - 10px);
  }

  &:hover {
    .product__name {
      background: $blueHover;
    }
  }
}

.product__image {
  aspect-ratio: 1 / 1;
  background: #f6f6f6;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product__number {
  position: absolute;
  width: 50px;
  height: 50px;
  background: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

.product__name {
  background: $blue;
  padding: 15px 20px;
  color: $white;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  margin-bottom: 30px;
  transition: 0.2s ease-out;

  @media (max-width: 767px) {
    font-size: 13px;
    line-height: 22px;
    padding: 10px;
  }
}

.product__descr {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding-bottom: 22px;
  border-bottom: 1px solid $borderToLight;
  flex-grow: 1;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
}

.info {
  display: flex;
  flex-wrap: wrap;
  gap: 38px;
  padding-bottom: 90px;

  @media (max-width: 767px) {
    padding-bottom: 50px;
    gap: 20px;
  }
}

.info__item {
  width: calc(20% - 30.4px);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    width: calc(33.33% - 26px);
  }

  @media (max-width: 767px) {
    width: calc(50% - 10px);
  }
}

.info__image {
  height: 116px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;
}

.info__text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 22px;
  }
}
