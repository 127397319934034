.footer {
  background: $blue;
  padding: 60px 0;

  @media (max-width: 767px) {
    padding: 25px 0;
  }

  .container {
    flex-direction: row;
    align-items: flex-start;

    @media (max-width: 1024px) {
      flex-direction: column;
      gap: 60px;
    }
  }
}

.footer__left {
  display: flex;
  gap: 60px;
  align-items: flex-start;
  margin-right: auto;

  @media (max-width: 1360px) {
    gap: 30px;
    width: 100%;
  }
}

.footer__item {
  display: flex;
  flex-direction: column;

  @media (max-width: 1360px) {
    width: 50%;
  }
}

.footer__title {
  color: $white;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 33px;
}

.footer__schedule {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }

  span {
    &:first-child {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: $white;
      opacity: 0.5;
    }

    &:last-child {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: $white;
    }
  }
}

.footer__address {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  max-width: 245px;
  color: $white;
  opacity: 0.5;
  margin-bottom: 42px;
}

.footer__social {
  display: flex;
  gap: 20px;
}

.footer__social-item {
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    svg path {
      fill: $blueHover;
    }
  }
}

.footer__top {
  padding-bottom: 24px;
  border-bottom: 1px solid $borderToDark;
  display: flex;
  align-items: flex-start;
  gap: 50px;

  @media (max-width: 1024px) {
    justify-content: space-between;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.footer__cert {
  display: flex;
  align-items: center;
  gap: 20px;
}

.footer__cert-img {
  display: flex;
}

.footer__cert-info {
  display: flex;
  flex-direction: column;

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $white;

    &:last-child {
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
    }
  }

  a {
    font-size: 11px;
    color: $white;
    font-weight: 500;
    line-height: 16px;
    margin-top: 8px;

    &:hover {
      color: $blueHover;
    }
  }
}

.footer__copyright {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  span {
    &:first-child {
      font-size: 35px;
      color: $blueHover;
      font-weight: 400;
      line-height: 45px;
    }

    &:last-child {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      color: $white;
    }
  }
}

.footer__contacts {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 50px;
  padding-top: 42px;

  @media (max-width: 1024px) {
    justify-content: flex-start;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding-top: 25px;
  }
}

.footer__link {
  display: flex;
  align-items: center;
  gap: 10px;
  color: $white;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;

  &:hover {
    color: $blueHover;
  }
}

.footer__right {
  @media (max-width: 1024px) {
    width: 100%;
  }
}
