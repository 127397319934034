.product-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 100px;

  @media (max-width: 1024px) {
    margin-bottom: 50px;
  }

  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
}

.product__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  flex-wrap: wrap;
  margin-bottom: 10px;

  @media (max-width: 1360px) {
    gap: 20px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 60px;
    font-weight: 700;
    line-height: 60px;
    margin: 0;
    text-align: left;

    @media (max-width: 1360px) {
      font-size: 40px;
      line-height: 40px;
    }

    @media (max-width: 767px) {
      font-size: 28px;
      line-height: 28px;
    }
  }
}

.product__pdf {
  display: flex;
  align-items: center;
  gap: 20px;

  span {
    position: relative;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: $black;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      width: 0;
      transform: translateX(-50%);
      height: 2px;
      background: $black;
      transition: 0.2s ease-out;
      bottom: -4px;
    }
  }

  &:hover {
    span {
      &:before {
        width: 100%;
      }
    }
  }
}

.product__articul {
  display: flex;
  align-items: center;
  color: #a3a3a3;
  font-size: 25px;
  font-weight: 500;
  font-style: normal;
  line-height: 30px;
  gap: 4px;
  margin-bottom: 50px;

  span {
    &:last-child {
      color: $black;
      font-weight: 800;
    }
  }
}

.product__body {
  display: flex;
  gap: 50px;
  position: relative;
  padding-bottom: 68px;

  @media (max-width: 1360px) {
    gap: 15px;
  }

  @media (max-width: 1024px) {
    gap: 20px;
    flex-wrap: wrap;
    padding-bottom: 40px;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: calc(100% - (33.33% + 50px));
    height: 1px;
    background: $borderToLight;
    left: 0;
    bottom: 0;

    @media (max-width: 1024px) {
      display: none;
    }
  }
}

.product__col {
  width: calc(33.33% - 33.33px);
  display: flex;
  flex-direction: column;

  @media (max-width: 1360px) {
    width: calc(33.33% - 10px);
  }

  @media (max-width: 1024px) {
    width: calc(50% - 10px);
  }

  @media (max-width: 767px) {
    width: 100%;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.product__photo {
  display: flex;
  width: 100%;
  margin-bottom: 50px;
  height: 350px;

  @media (max-width: 767px) {
    height: auto;
  }
}

.product__description-title {
  font-size: 25px;
  margin-bottom: 20px;
  font-weight: 800;
  line-height: 30px;
}

.product__description {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.product__info {
  display: flex;
  flex-direction: column;
  padding: 50px 50px 40px;
  gap: 30px;
  background: #f6f6f6;
  margin-bottom: 100px;
  min-height: 350px;

  @media (max-width: 1360px) {
    padding: 30px 30px 10px;
  }

  @media (max-width: 767px) {
    min-height: auto;
  }

  ul {
    li {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      span {
        &:last-child {
          font-weight: 700;
        }
      }
    }
  }
}

.product__info-title {
  font-size: 25px;
  font-weight: 800;
  line-height: 30px;
}

.product__form {
  display: none;
  flex-direction: column;
  padding: 50px;
  background: $blueThird;
  gap: 10px;

  button {
    color: $black;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    align-self: end;
    background: transparent;
    border: 0;
    outline: none;
    margin-top: 10px;
    cursor: pointer;
    transition: 0.2s ease-out;

    &:hover {
      color: $blueHover;
    }
  }

  @media (max-width: 1360px) {
    padding: 30px;
  }

  .form-group {
    input,
    textarea {
      background: $white;
      color: $black;

      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    textarea {
      height: 150px;
    }
  }
}

.product__form-title {
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: 800;
  line-height: 30px;
}

.related {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 92px;

  @media (max-width: 1024px) {
    margin-bottom: 50px;
  }

  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
}

.related__title {
  font-size: 60px;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 36px;

  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 30px;
  }
}

.related__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;

  @media (max-width: 767px) {
    gap: 10px;
  }
}

.related__item {
  display: flex;
  flex-direction: column;
  width: calc(25% - 37.5px);
  color: $black;

  @media (max-width: 1024px) {
    width: calc(50% - 25px);
  }

  @media (max-width: 767px) {
    width: calc(50% - 5px);
  }

  &:hover {
    .related__name {
      color: $blueHover;
    }
  }
}

.related__photo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 1 / 1;
  padding-bottom: 10px;
  border-bottom: 1px solid $borderToLight;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    height: 170px;
    aspect-ratio: auto;

    img {
      object-fit: contain;
      max-height: 100%;
      max-width: 100%;
    }
  }
}

.related__name {
  font-size: 20px;
  font-weight: 800;
  line-height: 26px;
  transition: 0.2s ease-out;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 22px;
  }
}

.related__descr {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
}

.product__list {
  display: flex;
  flex-direction: column;
  padding: 50px 50px 0;
  gap: 30px;
  background: #f6f6f6;
  min-height: 415px;

  @media (max-width: 1360px) {
    padding: 30px 30px 0;
  }

  @media (max-width: 767px) {
    min-height: auto;
  }

  ul {
    li {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      span {
        &:last-child {
          font-weight: 700;
        }
      }
    }
  }
}

.product__list-btn {
  width: calc(100% + 100px);
  margin-left: -50px;
  padding: 20px 0;
  text-align: center;
  color: $white;
  background: $blueSecondary;
  text-transform: uppercase;
  margin-top: auto;

  @media (max-width: 1360px) {
    width: calc(100% + 60px);
    margin-left: -30px;
  }

  &:hover {
    background: $blueHover;
  }
}
