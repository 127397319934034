@font-face {
  font-family: "Axiforma";
  src: url("../fonts/Axiforma-SemiBold.woff2") format("woff2"), url("../fonts/Axiforma-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Axiforma";
  src: url("../fonts/Axiforma-Medium.woff2") format("woff2"), url("../fonts/Axiforma-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Axiforma";
  src: url("../fonts/Axiforma-ExtraBold.woff2") format("woff2"), url("../fonts/Axiforma-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Axiforma";
  src: url("../fonts/Axiforma-Bold.woff2") format("woff2"), url("../fonts/Axiforma-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Axiforma";
  src: url("../fonts/Axiforma-Regular.woff2") format("woff2"), url("../fonts/Axiforma-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
:root {
  --app-height: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: "Axiforma";
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 189px;
}
@media (max-width: 767px) {
  body {
    padding-top: 109px;
  }
}
body.redirect-page {
  padding-top: 0 !important;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
}

a,
a:active {
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  text-decoration: none;
  transition: 0.3s;
}

h2 {
  font-size: 60px;
  margin-bottom: 84px;
  font-weight: 700;
  line-height: 60px;
  text-align: center;
}
@media (max-width: 1024px) {
  h2 {
    margin-bottom: 40px;
    font-size: 34px;
    line-height: 34px;
  }
}
@media (max-width: 767px) {
  h2 {
    margin-bottom: 25px;
  }
}

p {
  margin: 0;
}

svg path,
svg circle,
svg line,
svg polyline {
  transition: 0.3s;
}

img {
  max-width: 100%;
}

.container {
  width: 1150px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1360px) {
  .container {
    width: 960px;
    padding: 0 16px;
  }
}
@media (max-width: 1024px) {
  .container {
    width: 720px;
  }
}
@media (max-width: 767px) {
  .container {
    width: 100%;
  }
}

.tabs {
  position: relative;
}

.tabs__content {
  display: none;
}

.tabs__content.active {
  display: flex;
  flex-direction: column;
}

.hidden {
  overflow: hidden !important;
}
@media (max-width: 991px) {
  .hidden {
    padding-right: 0;
  }
}

hr {
  height: 1px;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin: 0;
  width: 100%;
}

.checkbox-container {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  position: relative;
  height: 20px;
  padding-left: 40px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: normal;
  color: #141f38;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  font-family: "Axiforma";
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid #0060aa;
  border-radius: 2px;
  transition: 0.3s;
}

.checkmark:after {
  content: "";
  position: absolute;
  opacity: 0;
  transition: 0.3s;
  left: 6px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid #0060aa;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox-container input:checked ~ .checkmark:after {
  opacity: 1;
}

.form-group {
  display: flex;
  width: 100%;
}
.form-group input {
  font-size: 16px;
  color: #ffffff;
  padding: 12px 20px;
  font-weight: 400;
  line-height: 24px;
  padding: 12px 20px;
  background: #004e8a;
  border-radius: 6px;
  border: 0;
  outline: none;
  width: 100%;
  font-family: "Axiforma";
}
.form-group input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.form-group textarea {
  font-size: 16px;
  color: #ffffff;
  padding: 12px 20px;
  font-weight: 400;
  line-height: 24px;
  padding: 12px 20px;
  background: #004e8a;
  border-radius: 6px;
  border: 0;
  outline: none;
  width: 100%;
  resize: none;
  font-family: "Axiforma";
  height: 200px;
}
.form-group textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.form-group .select2 {
  width: 100% !important;
}
.form-group .select2 .select2-selection--single {
  border: 0;
  border-radius: 5px;
  height: 45px;
}
.form-group .select2 .select2-selection--single .select2-selection__rendered {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 10px 20px;
}
.form-group .select2 .select2-selection--single .select2-selection__arrow {
  height: 45px;
  width: 40px;
}
.form-group .select2 .select2-selection--single .select2-selection__arrow b {
  background: url(../images/icons/arrow.svg) no-repeat;
  background-size: 10px 10px;
  background-position: center;
  border: 0;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 15px;
  top: 14px;
}
.form-group .select2.select2-container--open .select2-selection__arrow {
  transform: rotate(180deg);
}

.select2-dropdown {
  border: 0 !important;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: 0.3s;
  border: 0;
  outline: 0;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  box-shadow: 0px 4px 50px #000000;
  background: #0060aa;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  flex-shrink: 0;
  border-radius: 10px;
  border: 0;
  padding: 25px;
  font-family: "Axiforma";
  color: #141f38;
}
@media (max-width: 767px) {
  .btn {
    font-size: 20px;
    padding: 16px;
  }
}
.btn:hover {
  box-shadow: 0px 4px 30px #000000;
  text-shadow: #fff 5px 2px 20px;
}

.header {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.header--redirect {
  position: relative;
}
.header--redirect .header__bottom {
  display: none;
}

.header__top {
  padding: 26px 0;
  background: #ffffff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.header__top .container {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .header__top .container {
    justify-content: flex-start;
  }
}

.header__top-links {
  display: flex;
  align-items: center;
  gap: 50px;
}
@media (max-width: 1024px) {
  .header__top-links {
    gap: 25px;
  }
}
@media (max-width: 767px) {
  .header__top-links {
    gap: 10px;
    flex-direction: column;
    align-items: flex-end;
    flex-shrink: 0;
    margin-left: auto;
  }
  .header__top-links svg {
    display: none;
  }
}

.header__top-link {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #141f38;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
}
@media (max-width: 1024px) {
  .header__top-link {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (max-width: 767px) {
  .header__top-link {
    font-size: 13px;
    line-height: 16px;
  }
}
.header__top-link svg {
  flex-shrink: 0;
}
.header__top-link:hover {
  color: #47bdff;
}

.logo {
  margin-right: 20px;
}

.header__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #003c74;
  height: 80px;
  padding: 0 40px;
}
@media (max-width: 1024px) {
  .header__bottom {
    padding: 0 20px;
  }
}
@media (max-width: 767px) {
  .header__bottom {
    position: fixed;
    height: 100vh;
    height: var(--app-height);
    width: 100%;
    z-index: 500;
    left: 0;
    top: 0;
    flex-direction: column;
    align-items: flex-start;
    padding: 50px 20px 25px;
    transition: 0.4s ease-out;
    transform: translateX(-100%);
  }
  .header__bottom.active {
    transform: translateX(0);
  }
}

.nav {
  display: flex;
  align-items: center;
  gap: 50px;
}
@media (max-width: 1024px) {
  .nav {
    gap: 16px;
  }
}
@media (max-width: 767px) {
  .nav {
    flex-direction: column;
    align-items: flex-start;
  }
}

.nav__link {
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}
@media (max-width: 1024px) {
  .nav__link {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 767px) {
  .nav__link {
    font-size: 24px;
    line-height: 28px;
  }
}
.nav__link:hover, .nav__link.active {
  color: #47bdff;
}

.language {
  display: flex;
  flex-direction: row-reverse;
}
@media (max-width: 767px) {
  .language {
    flex-direction: row;
  }
}

.language__current {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.3px;
  gap: 14px;
  cursor: pointer;
}
.language__current svg {
  transition: 0.2s ease-out;
  margin-top: -3px;
}
.language__current:hover svg path {
  fill: #47bdff;
}
@media (max-width: 767px) {
  .language__current:hover svg path {
    fill: #ffffff;
  }
}
.language__current.active svg {
  transform: rotate(90deg);
}
@media (max-width: 767px) {
  .language__current.active svg {
    transform: rotate(-90deg);
  }
  .language__current.active svg path {
    fill: #47bdff;
  }
}

.language__list {
  display: flex;
  align-items: center;
  margin-right: 20px;
  gap: 15px;
  width: 0;
  overflow: hidden;
  transition: 0.2s ease-out;
}
@media (max-width: 767px) {
  .language__list {
    margin-right: 0;
    margin-left: 20px;
  }
}
.language__list.active {
  width: 100%;
}

.language__link {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.3px;
}
.language__link:hover {
  color: #47bdff;
}

.header__close {
  display: none;
}
@media (max-width: 767px) {
  .header__close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.hamburger {
  display: none;
  flex-direction: column;
  gap: 6px;
  width: 25px;
  height: 12px;
  margin-left: 15px;
}
@media (max-width: 767px) {
  .hamburger {
    display: flex;
  }
}
.hamburger span {
  height: 2px;
  width: 100%;
  background: #141f38;
  flex-shrink: 0;
}

.footer {
  background: #0060aa;
  padding: 60px 0;
}
@media (max-width: 767px) {
  .footer {
    padding: 25px 0;
  }
}
.footer .container {
  flex-direction: row;
  align-items: flex-start;
}
@media (max-width: 1024px) {
  .footer .container {
    flex-direction: column;
    gap: 60px;
  }
}

.footer__left {
  display: flex;
  gap: 60px;
  align-items: flex-start;
  margin-right: auto;
}
@media (max-width: 1360px) {
  .footer__left {
    gap: 30px;
    width: 100%;
  }
}

.footer__item {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1360px) {
  .footer__item {
    width: 50%;
  }
}

.footer__title {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 33px;
}

.footer__schedule {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}
.footer__schedule:last-child {
  margin-bottom: 0;
}
.footer__schedule span:first-child {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.5;
}
.footer__schedule span:last-child {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #ffffff;
}

.footer__address {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  max-width: 245px;
  color: #ffffff;
  opacity: 0.5;
  margin-bottom: 42px;
}

.footer__social {
  display: flex;
  gap: 20px;
}

.footer__social-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer__social-item:hover svg path {
  fill: #47bdff;
}

.footer__top {
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  display: flex;
  align-items: flex-start;
  gap: 50px;
}
@media (max-width: 1024px) {
  .footer__top {
    justify-content: space-between;
  }
}
@media (max-width: 767px) {
  .footer__top {
    flex-direction: column;
  }
}

.footer__cert {
  display: flex;
  align-items: center;
  gap: 20px;
}

.footer__cert-img {
  display: flex;
}

.footer__cert-info {
  display: flex;
  flex-direction: column;
}
.footer__cert-info span {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #ffffff;
}
.footer__cert-info span:last-child {
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
}
.footer__cert-info a {
  font-size: 11px;
  color: #ffffff;
  font-weight: 500;
  line-height: 16px;
  margin-top: 8px;
}
.footer__cert-info a:hover {
  color: #47bdff;
}

.footer__copyright {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.footer__copyright span:first-child {
  font-size: 35px;
  color: #47bdff;
  font-weight: 400;
  line-height: 45px;
}
.footer__copyright span:last-child {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #ffffff;
}

.footer__contacts {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 50px;
  padding-top: 42px;
}
@media (max-width: 1024px) {
  .footer__contacts {
    justify-content: flex-start;
  }
}
@media (max-width: 767px) {
  .footer__contacts {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding-top: 25px;
  }
}

.footer__link {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #ffffff;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
}
.footer__link:hover {
  color: #47bdff;
}

@media (max-width: 1024px) {
  .footer__right {
    width: 100%;
  }
}

.offer {
  height: 550px;
  background: url(../images/banner-1.png) no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: -80px;
  padding-top: 174px;
}
@media (max-width: 1024px) {
  .offer {
    height: 350px;
    padding-top: 140px;
  }
}
@media (max-width: 767px) {
  .offer {
    margin-top: 0;
    padding-top: 80px;
  }
}
.offer h1 {
  font-size: 80px;
  color: #ffffff;
  font-weight: 800;
  line-height: 78px;
  max-width: 638px;
}
@media (max-width: 1024px) {
  .offer h1 {
    font-size: 50px;
    line-height: 50px;
    max-width: 450px;
  }
}
.offer h1 span {
  font-weight: 400;
}
.offer--inner {
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 174px;
  padding-bottom: 126px;
  height: 384px;
}
@media (max-width: 1024px) {
  .offer--inner {
    height: 240px;
    padding-top: 140px;
    padding-bottom: 80px;
  }
}
@media (max-width: 767px) {
  .offer--inner {
    padding-top: 80px;
    padding-bottom: 40px;
  }
}
.offer--inner > img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
}

.advantages {
  padding: 100px 0 50px;
}

.advantages__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 38px;
}
@media (max-width: 1024px) {
  .advantages__wrapper {
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .advantages__wrapper {
    gap: 20px;
  }
}

.advantages__item {
  width: calc(20% - 30.4px);
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 1024px) {
  .advantages__item {
    width: calc(33.33% - 26px);
  }
}
@media (max-width: 767px) {
  .advantages__item {
    width: calc(50% - 10px);
  }
}

.advantages__icon {
  display: flex;
  width: 120px;
  height: 120px;
  margin: 0 auto 30px;
}

.advantages__name {
  font-size: 25px;
  font-weight: 800;
  line-height: 30px;
  margin-bottom: 22px;
  text-align: center;
}
@media (max-width: 767px) {
  .advantages__name {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 15px;
  }
}

.advantages__text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  transition: 0.2s ease-out;
  margin-top: auto;
}

.products {
  padding-bottom: 100px;
}
@media (max-width: 767px) {
  .products {
    padding-bottom: 50px;
  }
}

.products__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}
@media (max-width: 767px) {
  .products__wrapper {
    gap: 20px;
  }
}

.product {
  display: flex;
  flex-direction: column;
  width: calc(25% - 37.5px);
  color: #141f38;
}
@media (max-width: 1024px) {
  .product {
    width: calc(50% - 25px);
  }
}
@media (max-width: 1024px) {
  .product {
    width: calc(50% - 10px);
  }
}
.product:hover .product__name {
  background: #47bdff;
}

.product__image {
  aspect-ratio: 1/1;
  background: #f6f6f6;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product__number {
  position: absolute;
  width: 50px;
  height: 50px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

.product__name {
  background: #0060aa;
  padding: 15px 20px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  margin-bottom: 30px;
  transition: 0.2s ease-out;
}
@media (max-width: 767px) {
  .product__name {
    font-size: 13px;
    line-height: 22px;
    padding: 10px;
  }
}

.product__descr {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding-bottom: 22px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  flex-grow: 1;
}
@media (max-width: 767px) {
  .product__descr {
    font-size: 14px;
    line-height: 20px;
  }
}

.info {
  display: flex;
  flex-wrap: wrap;
  gap: 38px;
  padding-bottom: 90px;
}
@media (max-width: 767px) {
  .info {
    padding-bottom: 50px;
    gap: 20px;
  }
}

.info__item {
  width: calc(20% - 30.4px);
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 1024px) {
  .info__item {
    width: calc(33.33% - 26px);
  }
}
@media (max-width: 767px) {
  .info__item {
    width: calc(50% - 10px);
  }
}

.info__image {
  height: 116px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;
}

.info__text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}
@media (max-width: 767px) {
  .info__text {
    font-size: 14px;
    line-height: 22px;
  }
}

.about-us {
  display: flex;
  flex-direction: column;
}

.about-us__text {
  display: flex;
  flex-wrap: wrap;
  gap: 40px 50px;
  padding: 80px 0 66px;
}
@media (max-width: 767px) {
  .about-us__text {
    padding: 40px 0;
  }
}
.about-us__text ul {
  flex-grow: 1;
}
.about-us__text ul li {
  position: relative;
  padding-left: 18px;
}
.about-us__text ul li:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 7px;
  background: #47bdff;
  border-radius: 50%;
  width: 5px;
  height: 5px;
}

.about-us__text-wrapper {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 600px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.about-us__text-title {
  font-size: 25px;
  font-weight: 800;
  line-height: 30px;
  width: 100%;
}

.certificate {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 100px;
}
@media (max-width: 767px) {
  .certificate {
    margin-bottom: 50px;
  }
}

.certificate__title {
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  color: #ffffff;
  background: #0060aa;
  padding: 30px 40px;
}
@media (max-width: 1024px) {
  .certificate__title {
    font-size: 22px;
    line-height: 28px;
  }
}
@media (max-width: 767px) {
  .certificate__title {
    padding: 20px;
    font-size: 18px;
    line-height: 24px;
  }
}

.certificate__text {
  color: #141f38;
  background: #e1f6ff;
  padding: 40px 50px;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}
@media (max-width: 1024px) {
  .certificate__text {
    padding: 30px 40px;
  }
}
@media (max-width: 767px) {
  .certificate__text {
    padding: 20px;
    font-size: 14px;
    line-height: 20px;
  }
}

.fakts {
  display: flex;
  flex-wrap: wrap;
}
.fakts > img {
  width: 100%;
}

.fakts__title {
  font-size: 60px;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 60px;
  width: 100%;
  text-align: center;
}
@media (max-width: 1024px) {
  .fakts__title {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 30px;
  }
}

.fakts__left {
  display: flex;
  width: 500px;
  height: 500px;
  overflow: hidden;
}
@media (max-width: 1360px) {
  .fakts__left {
    width: 400px;
    height: 430px;
  }
}
@media (max-width: 1024px) {
  .fakts__left {
    width: 100%;
    height: auto;
  }
}
.fakts__left img {
  transition: 0.2s ease-out;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fakts__left:hover img {
  transform: scale(1.02);
}

.fakts__right {
  display: flex;
  width: 650px;
  height: 500px;
  overflow: hidden;
}
@media (max-width: 1360px) {
  .fakts__right {
    width: 528px;
    height: 430px;
  }
}
@media (max-width: 1024px) {
  .fakts__right {
    width: 100%;
    height: auto;
  }
}
.fakts__right img {
  transition: 0.2s ease-out;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fakts__right:hover img {
  transform: scale(1.02);
}

.square {
  padding-top: 100px;
  padding-bottom: 70px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 100px;
}

.square__title {
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 48px;
  color: #0060aa;
  margin-bottom: 40px;
  padding-left: 50px;
}
@media (max-width: 767px) {
  .square__title {
    padding-left: 25px;
    font-size: 24px;
    line-height: 32px;
  }
}

.square__wrapper {
  display: flex;
  align-items: flex-start;
  gap: 50px;
  padding-left: 50px;
  position: relative;
}
@media (max-width: 1024px) {
  .square__wrapper {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .square__wrapper {
    padding-left: 25px;
  }
}
.square__wrapper:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: -188px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.15);
  height: 450px;
}

.square__info {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  gap: 35px;
  width: 400px;
  flex-shrink: 0;
  margin-right: auto;
}
@media (max-width: 767px) {
  .square__info {
    width: 100%;
  }
}

.square__item {
  position: relative;
  display: flex;
  align-items: center;
  gap: 6px;
}
.square__item:first-child {
  height: 130px;
  background: url(../images/map-1.svg) no-repeat;
  background-position: center;
}
.square__item:first-child .square__value {
  color: #9d2235;
}
.square__item:first-child:before {
  content: "";
  display: block;
  position: absolute;
  left: -55px;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: #9d2235;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 767px) {
  .square__item:first-child:before {
    left: -30px;
  }
}
.square__item:last-child {
  height: 160px;
  background: url(../images/map-2.svg) no-repeat;
  background-position: center;
}
.square__item:last-child .square__value {
  color: #0072ce;
}
.square__item:last-child:before {
  content: "";
  display: block;
  position: absolute;
  left: -55px;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: #0072ce;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 767px) {
  .square__item:last-child:before {
    left: -30px;
  }
}

.square__name {
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
}
@media (max-width: 767px) {
  .square__name {
    font-size: 28px;
    line-height: 34px;
  }
}

.square__value {
  font-size: 45px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
}
@media (max-width: 767px) {
  .square__value {
    font-size: 32px;
    line-height: 40px;
  }
}

.square__list {
  display: flex;
  flex-direction: column;
  list-style-type: disc;
}
.square__list li {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
}

.partners {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 100px;
}
@media (max-width: 767px) {
  .partners {
    margin-bottom: 50px;
  }
}

.partners__title {
  text-align: center;
  width: 100%;
  margin-bottom: 84px;
  font-size: 60px;
  font-weight: 700;
  line-height: 60px;
}
@media (max-width: 1024px) {
  .partners__title {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 30px;
  }
}

.partners__item {
  display: flex;
  aspect-ratio: 1/1;
  width: 20%;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1024px) {
  .partners__item {
    width: 33.33%;
    max-height: 150px;
  }
}
@media (max-width: 767px) {
  .partners__item {
    width: 50%;
    padding: 15px;
  }
}

.categories__top {
  background: url(../images/categories-main.png) no-repeat;
  background-size: cover;
  background-position: center;
  height: 550px;
  padding-top: 174px;
  margin-top: -80px;
}
@media (max-width: 991px) {
  .categories__top {
    padding: 120px 0 60px;
    height: auto;
  }
}
@media (max-width: 767px) {
  .categories__top {
    margin-top: 0;
    padding: 60px 0;
  }
}
.categories__top .container {
  flex-direction: row;
  align-items: flex-start;
}
@media (max-width: 991px) {
  .categories__top .container {
    flex-direction: column;
  }
}

.categories__top-left {
  display: flex;
  flex-direction: column;
  margin-right: auto;
}
@media (max-width: 991px) {
  .categories__top-left {
    margin-bottom: 30px;
  }
}
.categories__top-left h1 {
  font-size: 80px;
  color: #ffffff;
  font-weight: 800;
  line-height: 78px;
  margin-bottom: 22px;
}
@media (max-width: 1360px) {
  .categories__top-left h1 {
    font-size: 50px;
    line-height: 54px;
  }
}
.categories__top-left a {
  display: flex;
  align-items: center;
}
.categories__top-left a span {
  margin: 0 20px 0 28px;
  color: #ffffff;
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  position: relative;
}
@media (max-width: 1360px) {
  .categories__top-left a span {
    font-size: 18px;
    line-height: 24px;
  }
}
.categories__top-left a span:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 3px;
  background: #ffffff;
  transition: 0.2s ease-out;
  left: 50%;
  transform: translateX(-50%);
  bottom: -4px;
}
.categories__top-left a:hover span:before {
  width: 100%;
}

.categories__top-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 600px;
  flex-shrink: 0;
  margin-left: 50px;
}
@media (max-width: 1360px) {
  .categories__top-right {
    width: 400px;
    margin-left: 25px;
  }
}
@media (max-width: 991px) {
  .categories__top-right {
    margin-left: 0;
    width: 100%;
  }
}
.categories__top-right p {
  font-size: 16px;
  text-align: left;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 30px;
}
.categories__top-right a {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: #ffffff;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  margin-left: auto;
  position: relative;
}
.categories__top-right a:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 2px;
  background: #ffffff;
  transition: 0.2s ease-out;
  left: 50%;
  transform: translateX(-50%);
  bottom: -4px;
}
.categories__top-right a:hover:before {
  width: 100%;
}

.categories__nav {
  display: flex;
  align-items: center;
  padding: 30px 40px;
  background: #003c74;
  gap: 50px;
  margin-bottom: 60px;
}
@media (max-width: 991px) {
  .categories__nav {
    gap: 20px;
    justify-content: space-between;
  }
}
@media (max-width: 767px) {
  .categories__nav {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 30px;
    gap: 15px;
    margin-bottom: 30px;
  }
}

.categories__nav-link {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.2s ease-out;
}
@media (max-width: 767px) {
  .categories__nav-link {
    font-size: 14px;
    line-height: 18px;
  }
}
.categories__nav-link.active {
  pointer-events: none;
}
.categories__nav-link.active, .categories__nav-link:hover {
  color: #47bdff;
}

.categories__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  margin-bottom: 70px;
}
.categories__wrapper--second {
  padding-top: 100px;
}
@media (max-width: 1024px) {
  .categories__wrapper--second {
    padding-top: 50px;
  }
}
@media (max-width: 1024px) {
  .categories__wrapper {
    gap: 20px;
    margin-bottom: 30px;
  }
}
.categories__wrapper--inner {
  gap: 34px;
  width: calc(100% - 250px);
}
@media (max-width: 1024px) {
  .categories__wrapper--inner {
    gap: 20px;
  }
}
@media (max-width: 767px) {
  .categories__wrapper--inner {
    width: 100%;
    order: 2;
  }
}

.categories__title {
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 36px;
}
@media (max-width: 767px) {
  .categories__title {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 20px;
  }
}

.categories-descr-title {
  font-size: 80px;
  color: #141f38;
  font-weight: 800;
  line-height: 78px;
  margin-bottom: 33px;
}
@media (max-width: 1024px) {
  .categories-descr-title {
    font-size: 50px;
    line-height: 54px;
  }
}
@media (max-width: 767px) {
  .categories-descr-title {
    font-size: 30px;
    line-height: 34px;
  }
}

.categories__descr {
  display: flex;
  background: #e1f6ff;
  align-items: flex-start;
  margin-bottom: 100px;
}
@media (max-width: 1024px) {
  .categories__descr {
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .categories__descr {
    margin-bottom: 30px;
    flex-direction: column;
  }
}

.categories__descr-link {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
@media (max-width: 1024px) {
  .categories__descr-link img {
    max-width: 60px;
  }
}
.categories__descr-link span {
  margin: 0 20px 0 28px;
  color: #141f38;
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  position: relative;
}
@media (max-width: 1360px) {
  .categories__descr-link span {
    font-size: 18px;
    line-height: 24px;
  }
}
@media (max-width: 1024px) {
  .categories__descr-link span {
    font-size: 16px;
    line-height: 22px;
    margin: 0 15px 0 20px;
  }
}
.categories__descr-link span:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 3px;
  background: #141f38;
  transition: 0.2s ease-out;
  left: 50%;
  transform: translateX(-50%);
  bottom: -4px;
}
.categories__descr-link:hover span:before {
  width: 100%;
}

.categories__descr-nav {
  width: 300px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 67px 50px;
}
@media (max-width: 1024px) {
  .categories__descr-nav {
    padding: 60px 30px;
  }
}
@media (max-width: 767px) {
  .categories__descr-nav {
    padding: 20px;
  }
}
.categories__descr-nav a {
  display: flex;
  align-items: center;
  gap: 20px;
}
.categories__descr-nav a span:first-child {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #0060aa;
  width: 20px;
}
.categories__descr-nav a span:last-child {
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: #141f38;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  transition: 0.2s ease-out;
}
.categories__descr-nav a:hover span:last-child {
  color: #0060aa;
}

.categories__descr-nav-title {
  font-size: 25px;
  font-weight: 800;
  line-height: 30px;
  margin-bottom: 43px;
}
@media (max-width: 767px) {
  .categories__descr-nav-title {
    margin-bottom: 0;
  }
}

.categories__descr-body {
  display: flex;
  flex-direction: column;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  padding: 50px;
}
@media (max-width: 1024px) {
  .categories__descr-body {
    padding: 40px;
  }
}
@media (max-width: 767px) {
  .categories__descr-body {
    padding: 20px;
    border-left: 0;
  }
}
.categories__descr-body p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
}
.categories__descr-body p:last-child {
  margin-bottom: 0;
}

.categories__descr-important {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 20px;
}
@media (max-width: 1024px) {
  .categories__descr-important {
    font-size: 16px;
    line-height: 22px;
  }
}

.categories-inner {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 50px;
}
@media (max-width: 767px) {
  .categories-inner {
    gap: 20px;
    flex-direction: column;
  }
}

.categories-inner__filter {
  width: 200px;
}
@media (max-width: 767px) {
  .categories-inner__filter {
    order: 1;
    width: 100%;
  }
}
.categories-inner__filter .description-body__filter {
  width: 100%;
}
@media (max-width: 1024px) {
  .categories-inner__filter .description-body__filter {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .categories-inner__filter .description-body__filter .description-body__filter-item .select2-container {
    width: 200px !important;
  }
}
@media (max-width: 767px) {
  .categories-inner__filter .description-body__filter .description-body__filter-item .select2-container {
    width: 100% !important;
  }
}
.categories-inner__filter .description-body__filter-button {
  margin-top: 40px;
}

.categories-inner__filter-title {
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: 30px;
  margin-bottom: 30px;
}

.category {
  display: flex;
  width: calc(25% - 25.5px);
  flex-direction: column;
}
@media (max-width: 1360px) {
  .category {
    width: calc(33.33% - 25.5px);
  }
}
@media (max-width: 1024px) {
  .category {
    width: calc(50% - 10px);
  }
}
.category:hover .category__name {
  background: #47bdff;
}

.category__photo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
  width: 100%;
  background: #f6f6f6;
}

.category__number {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  background: #ffffff;
  color: #141f38;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 10;
}

.category__name {
  padding: 16px 20px;
  font-family: Axiforma;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #ffffff;
  background: #0060aa;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: 0.2s ease-out;
}

.categories-inner__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 150px;
  width: 100%;
  margin-bottom: 20px;
}
@media (max-width: 1024px) {
  .categories-inner__head {
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
  }
}
@media (max-width: 767px) {
  .categories-inner__head {
    order: 0;
  }
}

.categories-inner__name {
  font-size: 80px;
  font-weight: 800;
  line-height: 78px;
}
@media (max-width: 1024px) {
  .categories-inner__name {
    font-size: 54px;
    line-height: 60px;
  }
}

.categories-inner__text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.contacts {
  display: flex;
  margin-bottom: 96px;
}
@media (max-width: 1024px) {
  .contacts {
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .contacts {
    margin-bottom: 30px;
    flex-direction: column;
  }
}

.maps {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
}

.map__item {
  position: relative;
  width: 100%;
}

.map__title {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background: #0060aa;
  padding: 20px 25px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
}

.contacts__form {
  display: flex;
  flex-direction: column;
  padding: 50px;
  background: #0060aa;
  width: 400px;
  flex-shrink: 0;
  gap: 20px;
}
@media (max-width: 1024px) {
  .contacts__form {
    gap: 15px;
    width: 300px;
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .contacts__form {
    width: 100%;
  }
}
.contacts__form button {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-family: "Axiforma";
  border: 0;
  outline: none;
  box-shadow: 0;
  background: transparent;
  color: #ffffff;
  cursor: pointer;
  align-self: end;
  transition: 0.2s ease-out;
}
.contacts__form button:hover {
  color: #47bdff;
}

.contacts__form-title {
  margin-bottom: 12px;
  font-size: 25px;
  font-weight: 800;
  line-height: 30px;
  color: #ffffff;
}

.requisites {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  margin-bottom: 93px;
}
@media (max-width: 1024px) {
  .requisites {
    margin-bottom: 50px;
    gap: 20px;
  }
}
@media (max-width: 767px) {
  .requisites {
    margin-bottom: 30px;
    gap: 30px;
  }
}

.requisites__item {
  width: calc(33.33% - 33.33px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 1024px) {
  .requisites__item {
    width: calc(50% - 10px);
  }
}
@media (max-width: 767px) {
  .requisites__item {
    width: 100%;
  }
}

.requisites__flag {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 20px;
}
.requisites__flag img {
  width: 100%;
  height: 100%;
}

.requisites__name {
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
}

.requisites__country {
  font-size: 25px;
  font-weight: 800;
  line-height: 30px;
  margin-bottom: 34px;
}
@media (max-width: 1024px) {
  .requisites__country {
    margin-bottom: 15px;
  }
}

ul {
  margin-bottom: 32px;
}
@media (max-width: 1024px) {
  ul {
    margin-bottom: 15px;
  }
}
ul li {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.requisites__links {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.requisites__link {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 99px;
  background: #e1f6ff;
  padding: 10px 20px 10px 10px;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  color: #141f38;
}
.requisites__link:hover {
  background: #47bdff;
  color: #ffffff;
}

.product-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 100px;
}
@media (max-width: 1024px) {
  .product-page {
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .product-page {
    margin-bottom: 30px;
  }
}

.product__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
@media (max-width: 1360px) {
  .product__title {
    gap: 20px;
    margin-bottom: 20px;
  }
}
.product__title h2 {
  font-size: 60px;
  font-weight: 700;
  line-height: 60px;
  margin: 0;
  text-align: left;
}
@media (max-width: 1360px) {
  .product__title h2 {
    font-size: 40px;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  .product__title h2 {
    font-size: 28px;
    line-height: 28px;
  }
}

.product__pdf {
  display: flex;
  align-items: center;
  gap: 20px;
}
.product__pdf span {
  position: relative;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #141f38;
}
.product__pdf span:before {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  width: 0;
  transform: translateX(-50%);
  height: 2px;
  background: #141f38;
  transition: 0.2s ease-out;
  bottom: -4px;
}
.product__pdf:hover span:before {
  width: 100%;
}

.product__articul {
  display: flex;
  align-items: center;
  color: #a3a3a3;
  font-size: 25px;
  font-weight: 500;
  font-style: normal;
  line-height: 30px;
  gap: 4px;
  margin-bottom: 50px;
}
.product__articul span:last-child {
  color: #141f38;
  font-weight: 800;
}

.product__body {
  display: flex;
  gap: 50px;
  position: relative;
  padding-bottom: 68px;
}
@media (max-width: 1360px) {
  .product__body {
    gap: 15px;
  }
}
@media (max-width: 1024px) {
  .product__body {
    gap: 20px;
    flex-wrap: wrap;
    padding-bottom: 40px;
  }
}
.product__body:before {
  content: "";
  display: block;
  position: absolute;
  width: calc(100% - (33.33% + 50px));
  height: 1px;
  background: rgba(0, 0, 0, 0.15);
  left: 0;
  bottom: 0;
}
@media (max-width: 1024px) {
  .product__body:before {
    display: none;
  }
}

.product__col {
  width: calc(33.33% - 33.33px);
  display: flex;
  flex-direction: column;
}
@media (max-width: 1360px) {
  .product__col {
    width: calc(33.33% - 10px);
  }
}
@media (max-width: 1024px) {
  .product__col {
    width: calc(50% - 10px);
  }
}
@media (max-width: 767px) {
  .product__col {
    width: 100%;
  }
}
.product__col p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.product__photo {
  display: flex;
  width: 100%;
  margin-bottom: 50px;
  height: 350px;
}
@media (max-width: 767px) {
  .product__photo {
    height: auto;
  }
}

.product__description-title {
  font-size: 25px;
  margin-bottom: 20px;
  font-weight: 800;
  line-height: 30px;
}

.product__description {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.product__info {
  display: flex;
  flex-direction: column;
  padding: 50px 50px 40px;
  gap: 30px;
  background: #f6f6f6;
  margin-bottom: 100px;
  min-height: 350px;
}
@media (max-width: 1360px) {
  .product__info {
    padding: 30px 30px 10px;
  }
}
@media (max-width: 767px) {
  .product__info {
    min-height: auto;
  }
}
.product__info ul li {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.product__info ul li span:last-child {
  font-weight: 700;
}

.product__info-title {
  font-size: 25px;
  font-weight: 800;
  line-height: 30px;
}

.product__form {
  display: none;
  flex-direction: column;
  padding: 50px;
  background: #e1f6ff;
  gap: 10px;
}
.product__form button {
  color: #141f38;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  align-self: end;
  background: transparent;
  border: 0;
  outline: none;
  margin-top: 10px;
  cursor: pointer;
  transition: 0.2s ease-out;
}
.product__form button:hover {
  color: #47bdff;
}
@media (max-width: 1360px) {
  .product__form {
    padding: 30px;
  }
}
.product__form .form-group input,
.product__form .form-group textarea {
  background: #ffffff;
  color: #141f38;
}
.product__form .form-group input::placeholder,
.product__form .form-group textarea::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.product__form .form-group textarea {
  height: 150px;
}

.product__form-title {
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: 800;
  line-height: 30px;
}

.related {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 92px;
}
@media (max-width: 1024px) {
  .related {
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .related {
    margin-bottom: 30px;
  }
}

.related__title {
  font-size: 60px;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 36px;
}
@media (max-width: 767px) {
  .related__title {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 30px;
  }
}

.related__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}
@media (max-width: 767px) {
  .related__wrapper {
    gap: 10px;
  }
}

.related__item {
  display: flex;
  flex-direction: column;
  width: calc(25% - 37.5px);
  color: #141f38;
}
@media (max-width: 1024px) {
  .related__item {
    width: calc(50% - 25px);
  }
}
@media (max-width: 767px) {
  .related__item {
    width: calc(50% - 5px);
  }
}
.related__item:hover .related__name {
  color: #47bdff;
}

.related__photo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 1/1;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .related__photo {
    height: 170px;
    aspect-ratio: auto;
  }
  .related__photo img {
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
  }
}

.related__name {
  font-size: 20px;
  font-weight: 800;
  line-height: 26px;
  transition: 0.2s ease-out;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .related__name {
    font-size: 16px;
    line-height: 22px;
  }
}

.related__descr {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
@media (max-width: 767px) {
  .related__descr {
    font-size: 14px;
    line-height: 18px;
  }
}

.product__list {
  display: flex;
  flex-direction: column;
  padding: 50px 50px 0;
  gap: 30px;
  background: #f6f6f6;
  min-height: 415px;
}
@media (max-width: 1360px) {
  .product__list {
    padding: 30px 30px 0;
  }
}
@media (max-width: 767px) {
  .product__list {
    min-height: auto;
  }
}
.product__list ul li {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.product__list ul li span:last-child {
  font-weight: 700;
}

.product__list-btn {
  width: calc(100% + 100px);
  margin-left: -50px;
  padding: 20px 0;
  text-align: center;
  color: #ffffff;
  background: #003c74;
  text-transform: uppercase;
  margin-top: auto;
}
@media (max-width: 1360px) {
  .product__list-btn {
    width: calc(100% + 60px);
    margin-left: -30px;
  }
}
.product__list-btn:hover {
  background: #47bdff;
}

.description-head {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 50px;
  margin-top: 40px;
}
@media (max-width: 1024px) {
  .description-head {
    margin-top: 0;
    margin-bottom: 30px;
  }
}

.description-head__name {
  font-size: 60px;
  font-weight: 700;
  line-height: 60px;
}
@media (max-width: 1024px) {
  .description-head__name {
    font-size: 48px;
    line-height: 54px;
  }
}

.description-head__articul span:first-child {
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  color: #a3a3a3;
}
.description-head__articul span:last-child {
  font-size: 25px;
  font-weight: 800;
  line-height: 30px;
}

.description-body-top {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  margin-bottom: 50px;
}
@media (max-width: 1360px) {
  .description-body-top {
    gap: 30px;
  }
}

.description-body__col {
  width: calc(33.33% - 33.33px);
  display: flex;
  flex-direction: column;
}
@media (max-width: 1360px) {
  .description-body__col {
    width: calc(33.33% - 20px);
  }
}
@media (max-width: 1024px) {
  .description-body__col {
    width: calc(50% - 15px);
  }
  .description-body__col:first-child {
    width: 100%;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }
}
@media (max-width: 767px) {
  .description-body__col {
    width: 100%;
  }
  .description-body__col:first-child {
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
  }
}

.description-body__photo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  aspect-ratio: 1/1;
  margin-bottom: 50px;
  width: 100%;
}
@media (max-width: 1024px) {
  .description-body__photo {
    max-width: 260px;
  }
}
@media (max-width: 767px) {
  .description-body__photo {
    margin-bottom: 20px;
    max-width: 100%;
  }
}

.description-body__actions {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
@media (max-width: 1024px) {
  .description-body__actions {
    gap: 20px;
  }
}
.description-body__actions a {
  display: flex;
  gap: 20px;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: #141f38;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}
.description-body__actions a:hover {
  color: #47bdff;
}

.description-body__text {
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 50px;
  flex-grow: 1;
}
@media (max-width: 1360px) {
  .description-body__text {
    padding: 25px;
  }
}

.description-body__info {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 50px;
  background: #f6f6f6;
}
@media (max-width: 1360px) {
  .description-body__info {
    padding: 25px;
  }
}

.description-body__info-title {
  font-size: 25px;
  font-weight: 800;
  line-height: 30px;
}

.description-body__info-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #0060aa;
}

.description-body__info-list {
  padding: 0;
  list-style-type: none;
  margin: 0;
}
@media (max-width: 1360px) {
  .description-body__info-list {
    padding-left: 15px;
  }
}
.description-body__info-list li {
  position: relative;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.description-body__info-list li:before {
  content: "";
  display: block;
  position: absolute;
  background: url(../images/icons/list-icon.svg) no-repeat;
  background-size: contain;
  width: 17px;
  height: 14px;
  left: -30px;
  top: 4px;
}

.description-body-bottom {
  display: flex;
  align-items: flex-start;
  gap: 50px;
  margin-bottom: 100px;
}
@media (max-width: 1024px) {
  .description-body-bottom {
    margin-bottom: 50px;
    flex-direction: column;
    gap: 25px;
  }
}

.description-body__filter {
  width: calc(32.5% - 25px);
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media (max-width: 1024px) {
  .description-body__filter {
    max-width: 100%;
    min-width: 350px;
  }
}

.description-body__filter-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.description-body__filter-item .select2-container {
  width: 200px !important;
}
@media (max-width: 1360px) {
  .description-body__filter-item .select2-container {
    width: 160px !important;
  }
}
.description-body__filter-item .select2-container .select2-selection--single {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border: 0;
  height: 45px;
}
.description-body__filter-item .select2-container .select2-selection--single .select2-selection__rendered {
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 0 20px;
  opacity: 0.7;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.description-body__filter-item .select2-container .select2-selection--single .select2-selection__arrow {
  height: 100%;
  right: 10px;
}

.description-body__filter-name {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.select2-results__option {
  padding: 8px 20px !important;
  font-size: 14px;
}

.description-body__links {
  display: flex;
  flex-direction: column;
  width: calc(67.5% - 25px);
  gap: 10px;
}
@media (max-width: 1024px) {
  .description-body__links {
    width: 100%;
  }
}
.description-body__links > a {
  color: #0060aa;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 0 20px;
  height: 45px;
  font-size: 16px;
  font-weight: 400;
  line-height: 50px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.description-body__links > a:hover {
  color: #47bdff;
}

.description-body__filter-button {
  margin-top: 50px;
}
.description-body__filter-button a {
  width: 100%;
  color: #ffffff;
  background: #003c74;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
}
@media (max-width: 1360px) {
  .description-body__filter-button a {
    height: 45px;
  }
}
.description-body__filter-button a:hover {
  background: #47bdff;
}

.description-body__pagination {
  width: 100%;
  margin-top: 50px;
  display: flex;
}

.description-body__pagination-prev,
.description-body__pagination-next {
  flex-grow: 1;
  background: #e1f6ff;
  border-left: 1px solid rgba(0, 96, 170, 0.15);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1360px) {
  .description-body__pagination-prev,
  .description-body__pagination-next {
    height: 45px;
  }
}
.description-body__pagination-prev:hover,
.description-body__pagination-next:hover {
  background: #47bdff;
}

.description-body__pagination-prev {
  border-left: 0;
  border-right: 1px solid rgba(0, 96, 170, 0.15);
}

.description-body__pagination-page {
  height: 60px;
  display: flex;
  width: 60px;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #0060aa;
  background: #e1f6ff;
}
@media (max-width: 1360px) {
  .description-body__pagination-page {
    width: 45px;
    height: 45px;
  }
}
.description-body__pagination-page:hover {
  background: #47bdff;
}
.description-body__pagination-page.active {
  background: #0060aa;
  color: #ffffff;
}

.redirect {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  flex-grow: 1;
  background-color: #003c74;
  padding: 50px 0;
}
@media (max-width: 767px) {
  .redirect {
    padding: 40px 0;
    gap: 20px;
  }
}

.redirect__item {
  font-size: 25px;
  color: #ffffff;
  max-width: 550px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.redirect__item img {
  width: 30px;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .redirect__item {
    padding: 0 20px;
    font-size: 16px;
  }
}