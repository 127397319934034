.categories__top {
  background: url(../images/categories-main.png) no-repeat;
  background-size: cover;
  background-position: center;
  height: 550px;
  padding-top: 174px;
  margin-top: -80px;

  @media (max-width: 991px) {
    padding: 120px 0 60px;
    height: auto;
  }

  @media (max-width: 767px) {
    margin-top: 0;
    padding: 60px 0;
  }

  .container {
    flex-direction: row;
    align-items: flex-start;

    @media (max-width: 991px) {
      flex-direction: column;
    }
  }
}

.categories__top-left {
  display: flex;
  flex-direction: column;
  margin-right: auto;

  @media (max-width: 991px) {
    margin-bottom: 30px;
  }

  h1 {
    font-size: 80px;
    color: $white;
    font-weight: 800;
    line-height: 78px;
    margin-bottom: 22px;

    @media (max-width: 1360px) {
      font-size: 50px;
      line-height: 54px;
    }
  }

  a {
    display: flex;
    align-items: center;

    span {
      margin: 0 20px 0 28px;
      color: $white;
      font-size: 22px;
      font-weight: 600;
      line-height: 26px;
      position: relative;

      @media (max-width: 1360px) {
        font-size: 18px;
        line-height: 24px;
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 3px;
        background: $white;
        transition: 0.2s ease-out;
        left: 50%;
        transform: translateX(-50%);
        bottom: -4px;
      }
    }

    &:hover {
      span {
        &:before {
          width: 100%;
        }
      }
    }
  }
}

.categories__top-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 600px;
  flex-shrink: 0;
  margin-left: 50px;

  @media (max-width: 1360px) {
    width: 400px;
    margin-left: 25px;
  }

  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }

  p {
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    line-height: 24px;
    color: $white;
    margin-bottom: 30px;
  }

  a {
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    color: $white;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    margin-left: auto;
    position: relative;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 2px;
      background: $white;
      transition: 0.2s ease-out;
      left: 50%;
      transform: translateX(-50%);
      bottom: -4px;
    }

    &:hover {
      &:before {
        width: 100%;
      }
    }
  }
}

.categories__nav {
  display: flex;
  align-items: center;
  padding: 30px 40px;
  background: $blueSecondary;
  gap: 50px;
  margin-bottom: 60px;

  @media (max-width: 991px) {
    gap: 20px;
    justify-content: space-between;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 30px;
    gap: 15px;
    margin-bottom: 30px;
  }
}

.categories__nav-link {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: $white;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.2s ease-out;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }

  &.active {
    pointer-events: none;
  }

  &.active,
  &:hover {
    color: $blueHover;
  }
}

.categories__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  margin-bottom: 70px;

  &--second {
    padding-top: 100px;

    @media (max-width: 1024px) {
      padding-top: 50px;
    }
  }

  @media (max-width: 1024px) {
    gap: 20px;
    margin-bottom: 30px;
  }

  &--inner {
    gap: 34px;
    width: calc(100% - 250px);

    @media (max-width: 1024px) {
      gap: 20px;
    }

    @media (max-width: 767px) {
      width: 100%;
      order: 2;
    }
  }
}

.categories__title {
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 36px;

  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 20px;
  }
}

.categories-descr-title {
  font-size: 80px;
  color: $black;
  font-weight: 800;
  line-height: 78px;
  margin-bottom: 33px;

  @media (max-width: 1024px) {
    font-size: 50px;
    line-height: 54px;
  }

  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 34px;
  }
}

.categories__descr {
  display: flex;
  background: $blueThird;
  align-items: flex-start;
  margin-bottom: 100px;

  @media (max-width: 1024px) {
    margin-bottom: 50px;
  }

  @media (max-width: 767px) {
    margin-bottom: 30px;
    flex-direction: column;
  }
}

.categories__descr-link {
  display: flex;
  align-items: center;
  margin-bottom: 50px;

  @media (max-width: 1024px) {
    img {
      max-width: 60px;
    }
  }

  span {
    margin: 0 20px 0 28px;
    color: $black;
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    position: relative;

    @media (max-width: 1360px) {
      font-size: 18px;
      line-height: 24px;
    }

    @media (max-width: 1024px) {
      font-size: 16px;
      line-height: 22px;
      margin: 0 15px 0 20px;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 3px;
      background: $black;
      transition: 0.2s ease-out;
      left: 50%;
      transform: translateX(-50%);
      bottom: -4px;
    }
  }

  &:hover {
    span {
      &:before {
        width: 100%;
      }
    }
  }
}

.categories__descr-nav {
  width: 300px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 67px 50px;

  @media (max-width: 1024px) {
    padding: 60px 30px;
  }

  @media (max-width: 767px) {
    padding: 20px;
  }

  a {
    display: flex;
    align-items: center;
    gap: 20px;

    span {
      &:first-child {
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        color: $blue;
        width: 20px;
      }

      &:last-child {
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        color: $black;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        transition: 0.2s ease-out;
      }
    }

    &:hover {
      span {
        &:last-child {
          color: $blue;
        }
      }
    }
  }
}

.categories__descr-nav-title {
  font-size: 25px;
  font-weight: 800;
  line-height: 30px;
  margin-bottom: 43px;

  @media (max-width: 767px) {
    margin-bottom: 0;
  }
}

.categories__descr-body {
  display: flex;
  flex-direction: column;
  border-left: 1px solid $borderToLight;
  padding: 50px;

  @media (max-width: 1024px) {
    padding: 40px;
  }

  @media (max-width: 767px) {
    padding: 20px;
    border-left: 0;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.categories__descr-important {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 22px;
  }
}

.categories-inner {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 50px;

  @media (max-width: 767px) {
    gap: 20px;
    flex-direction: column;
  }
}

.categories-inner__filter {
  width: 200px;

  @media (max-width: 767px) {
    order: 1;
    width: 100%;
  }

  .description-body__filter {
    width: 100%;

    @media (max-width: 1024px) {
      width: 100%;
      max-width: 100%;
      min-width: 100%;

      .description-body__filter-item .select2-container {
        width: 200px !important;
      }
    }

    @media (max-width: 767px) {
      .description-body__filter-item .select2-container {
        width: 100% !important;
      }
    }
  }

  .description-body__filter-button {
    margin-top: 40px;
  }
}

.categories-inner__filter-title {
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: 30px;
  margin-bottom: 30px;
}

.category {
  display: flex;
  width: calc(25% - 25.5px);
  flex-direction: column;

  @media (max-width: 1360px) {
    width: calc(33.33% - 25.5px);
  }

  @media (max-width: 1024px) {
    width: calc(50% - 10px);
  }

  &:hover {
    .category__name {
      background: $blueHover;
    }
  }
}

.category__photo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1 / 1;
  width: 100%;
  background: #f6f6f6;
}

.category__number {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  background: $white;
  color: $black;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 10;
}

.category__name {
  padding: 16px 20px;
  font-family: Axiforma;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: $white;
  background: $blue;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: 0.2s ease-out;
}

.categories-inner__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 150px;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
  }

  @media (max-width: 767px) {
    order: 0;
  }
}

.categories-inner__name {
  font-size: 80px;
  font-weight: 800;
  line-height: 78px;

  @media (max-width: 1024px) {
    font-size: 54px;
    line-height: 60px;
  }
}

.categories-inner__text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
