.redirect {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    flex-grow: 1;
    background-color: $blueSecondary;
    padding: 50px 0;

    @media (max-width: 767px) {
        padding: 40px 0;
        gap: 20px;
    }
}

.redirect__item {
    font-size: 25px;
    color: $white;
    max-width: 550px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 30px;
        margin-bottom: 10px;
    }

    @media (max-width: 767px) {
        padding: 0 20px;
        font-size: 16px;
    }
}
